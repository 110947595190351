export default {
 data: function () {
    return {
      gefspotAPI: '/api',
      brand: null,
      brandcolor: null,
      color: null
    }
  },
  methods: {
    getBrandColor () {
      return this.color;
    },
    getBranding() {
      return this.$http.get(this.$root.gefspotAPI + "/getbranding")
      .then(response => {
        console.log (response)
        console.log (response.data)
        
        switch (response.data.branding) {
          case "1":
            this.brand      = "GEFSPOT - Leistungssport Austria"
            this.brandcolor = "red accent-4" 
            this.color      = "red" 
            break
          case "2":
            this.brand      = "SPOMED - Sportland Niederösterreich"
            this.brandcolor = "indigo darken-3"
            this.color      = "indigo" 
            break
          default:
            this.brand      = "Sportmedizinische Untersuchungen"
            this.brandcolor = "green accent-6"            
            this.color      = "green" 
        }
        
      });
    }
  },
  mounted () {  
  }
}