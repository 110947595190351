<template>

  <v-container v-if="this.$root.adminonly">

    <h2>Logs</h2>
    <br />

    <v-card class="mb-5 pb-3">
      <v-card-text>
        <div>
          Sie sehen hier alle Log-Einträge zu Aktivitäten, die vom System protokolliert wurden. Sie können nach Typ oder per Freitext suchen.
        </div>
      </v-card-text>
    </v-card>


    <v-container row wrap class="justify-md-space-between">

  
      <v-row align="center">

        <!--<v-col cols="6">
          <v-select
            v-model="verbaendeselect"
            :items="verbaendeitems"
            v-on:change="changeType()"
            item-text="state"
            item-value="abbr"
            label="Select"
            persistent-hint
            return-object
            single-line
          ></v-select>
        </v-col>-->

        <v-col cols="12">
          <v-text-field
            v-model="search"
            prepend-icon="mdi-magnify"
            label="Suche"
            filled
            clearable
          ></v-text-field>
        </v-col>

      </v-row>

    </v-container>

    <v-data-table
      v-bind:headers="headers"
      v-bind:items="logs"
      v-bind:search="search"
      :footer-props="{'items-per-page-options':[10, 25, 50, 100, -1]}"      
        sort-by="name"
      :loading="standby"
      class="elevation-1"
    >

      <template v-slot:item="row">
          <tr class="">
            <td class="mobile-full-width">{{row.item.datetime}}</td>
            <td class="mobile-full-width">{{row.item.type}}</td>
            <td class="mobile-full-width">{{row.item.user_id}}</td>
            <td class="mobile-full-width">{{row.item.user_name}}</td>
            <td class="mobile-full-width text-pre-wrap">{{row.item.entry}}</td>
          </tr>
      </template>      

    </v-data-table>

    <v-container>
        <br />
        <v-row align="center">
        <!--<download-csv
          :data = "logs">
          <v-btn 
            dark
            color="grey darken-1"
            >
            <v-icon left>mdi-file-download</v-icon> CSV Export Filter Jahr/Verband
          </v-btn>
        </download-csv>-->

        <download-csv
          :data = "logs">
          <v-btn 
            dark
            color="grey darken-1"
            >
            <v-icon left>mdi-file-download</v-icon> CSV Export - alle Logs
          </v-btn>
        </download-csv>
      </v-row>
    </v-container>  

    <v-dialog
      v-model="standby"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Einen Moment bitte...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="successDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">Erfolg</v-card-title>

        <v-card-text>
          {{ this.successDialogMsg }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            text
            @click="successDialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>

  <v-container v-else>
    <h2>Kein Zugriff</h2>
    <router-link to="/login">Loggen Sie sich ein</router-link>
  </v-container>

</template>

<script>
  export default {
    data () {
      return {
        notvalidated: false,
        standby: false,

        successDialog: false,
        successDialogMsg: 'Der Vorgang wurde erfolgreich beendet!',
        search: '',
        
        logs: [],

        pagination: {},
        headers: [
          { text: 'Zeitpunkt (UTC)', value: 'datetime', sortable: true },
          { text: 'Type', value: 'type', sortable: true },
          { text: 'User ID', value: 'user_id', sortable: true },
          { text: 'User Name', value: 'user_name', sortable: true },
          { text: 'Eintrag', value: 'entry', sortable: true },
        ],
        
        periodselect: [],
        perioditems: [],
        
        valid: false
      }

    },
    mounted () {
      this.$root.checkIfLoggedIn()

      this.getLogs()
      
    },
    methods: {
      formatDate(date) {
        date = date.substring(0, date.indexOf(' '));
        // return new Date(date).toLocaleString();
        return date;
      },
      getLogs () {

        let thisUser = this.$root.getUser()

        let _this = this
        _this.standby = true

        let data = {
          'userId': thisUser.userId,
          'authkey': thisUser.authkey
        }
        data = JSON.stringify(data);

        var xhr = new XMLHttpRequest();
        xhr.open("POST", this.$root.gefspotAPI + "/logs/get-all/", true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
        xhr.send("data=" + data);
        xhr.onload = function() {
          _this.standby = false
          console.log (this.responseText)          
          let response = JSON.parse(this.responseText)
          console.log (response)
          _this.logs = response
        };

      },

    }
  }
</script>

<style>
  .v-btn {
    margin-right: 16px;
  }  
  tr:nth-child(even) {
    background-color: #f8f8f8;
  }
  @media only screen and (max-width: 768px) {
    .mobile-full-width {
      display: flex;
      width: 100% !important;
      margin-left: 16px !important;
      border-top: 0;
      padding: 5px 16px !important;
    }
  }
</style>