<template>
  <v-container fluid fill-height>

    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-6">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Login</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field prepend-icon="mdi-account" name="login" label="E-Mail" type="text" v-model="username"></v-text-field>
              <v-text-field prepend-icon="mdi-lock-question" name="password" label="Password" id="password" type="password" v-model="password"></v-text-field>
            </v-form>
          </v-card-text>
                <v-alert
        v-model="loginFailed"
        dense
        outlined
        type="error"
      >          
        Login nicht möglich.<br />
        <p v-html="loginFailedMsg"></p>
      </v-alert>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" v-on:click="login">Login</v-btn>
            <!--<v-btn color="secondary" v-on:click="checkStatus">checkStatus</v-btn>-->
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog
      v-model="standby"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Einen Moment bitte...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-container>


</template>

<script>
  export default {
    data: () => ({
      drawer: false,
      username: null,
      password: null,
      loginFailed: false,
      loginFailedMsg: '',
      error: null
    }),
    props: {
      source: String
    },
    mounted () {
      this.$root.drawer = false      
      // console.log(this.$auth.redirect())
      // Can set query parameter here for auth redirect or just do it silently in login redirect.
    },
    methods: {
      login () {
        // var redirect = this.$auth.redirect()
        let _this = this
        _this.standby = true
        _this.loginFailed = false

        let data = {
          username: this.username,
          password: this.password
        }
        data = JSON.stringify(data);

        var xhr = new XMLHttpRequest();
        xhr.open("POST", this.$root.gefspotAPI + "/users/login/", true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
        xhr.send("data=" + data);

        xhr.onload = function() {
          _this.standby = false
          // console.log(this.responseText);
          var response = JSON.parse(this.responseText)
          // console.log (response)

          if (response.status == 'error') {
            _this.loginFailed = true
            _this.loginFailedMsg = response.msg
          }
          else if (response.status == 'success') {
            localStorage.setItem('user',JSON.stringify(response));
            // console.log ('erfolgreich eingeloggt!!!');
            _this.$root.loggedIn()
            _this.$root.isAdmin()            
            _this.$router.push('/')
          }
        }

      }
    }
  }
</script>
<style>
  /*
  nav.v-navigation-drawer { 
    display: none; 
    width: 0 !important; 
  }
  header.v-app-bar { left: 0 !important; }
  main.content { padding: 64px 0px 0px 0px !important; }
  .v-app-bar__nav-icon { display: none !important; }
  */
</style>