<template>

  <v-container v-if="this.$root.adminonly && this.$root.loggedin">

    <h2>User</h2>
    <br />

    <v-card class="mb-5 pb-3">
      <v-card-text class="text-sm-left">
        Ein User ist eine Untersuchungsstelle. User können sich einloggen, Personenlisten einsehen, sowie eine Untersuchung als erfolgt markieren und gegebenenfalls Notizen hinzufügen. Sie können hier neue User anlegen, bestehende editieren und Passwörter ändern.
      </v-card-text>
    </v-card>

    <v-container row wrap class="justify-md-space-between">
      <v-flex xs12>
        <v-text-field
          v-model="search"
          prepend-icon="mdi-magnify"
          label="Suche"
          filled
          clearable
        ></v-text-field>
      </v-flex>
    </v-container>

    <v-data-table
      v-bind:headers="headers"
      v-bind:items="users"
      v-bind:search="search"
      :footer-props="{'items-per-page-options':[10, 25, 50, 100, -1]}"      
      sort-by="id"
      :loading="standby"
      class="elevation-1"      
    >

      <template slot="headers" slot-scope="props">
          <tr>
              <th v-for="header in props.headers" :key="header.value">
                  {{ header.text }}
              </th>
          </tr>
      </template>

      <template v-slot:item="row">
          <tr>
            <td><v-chip :color="colorUserRolle(row.item.roles_mask)" dark >{{ showUserRolle(row.item.roles_mask) }}</v-chip></td>
            <td>{{row.item.id}}</td>
            <td>{{row.item.name}}</td>
            <td>{{row.item.zusatzinfo}}</td>
            <td>{{row.item.email}}</td>
            <td>
              <v-icon
                class="mr-2"
                @click="editUser(row.item.id)"
              >
                mdi-pencil
              </v-icon>
            </td>            
            <td>
              <v-icon
                class="mr-2"
                @click="deleteItem(row.item.id)"
              >
                mdi-delete
              </v-icon>
            </td>
          </tr>
      </template>      

    </v-data-table>

    <v-container>
      <br />
      <v-row align="center">
        <v-btn color="primary" dark @click.stop="newUserDialog()">Neuer User</v-btn>
        <!--<v-btn x-large color="primary" dark @click.stop="getUsers()">getUsers</v-btn>
        
        <v-btn color="secondary" v-on:click="this.$root.checkStatus">checkStatus</v-btn>
        <v-btn color="secondary" v-on:click="this.$root.hellouserauth">hellouserauth</v-btn>
        -->

        <download-csv
          :data   = "users">
          <v-btn 
            dark
            color="grey darken-1"
            >
            <v-icon left>mdi-file-download</v-icon> CSV Export - Alle User
          </v-btn>
        </download-csv>
      </v-row>
    </v-container>

    <v-dialog v-model="editUserDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{this.editUserDialogHeadline}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-alert
                :value="notvalidated"
                dense
                outlined
                type="error"
              >
                Der Vorgang kann nicht abgeschlossen werden. Beheben Sie bitte die Probleme bei den rot markierten Feldern.
              </v-alert>

              <v-col cols="12">
              <v-form ref="editUserForm">

                  <v-text-field
                    v-model="name"
                    :rules="nameRules"
                    label="Name"
                    hint="bspw.: 'Leistungssport Austria'"
                    required
                  ></v-text-field>


                  <v-text-field
                    v-model="zusatzinfo"
                    label="zusatzinfo"
                    hint="bspw.: 'Bundesinstitut für Leistungs- und Spitzensport'"  
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="E-mail"
                    required
                  ></v-text-field>

                  <v-select
                    v-model="rolle"
                    :items="userroles"
                    label="Rolle"
                    value="Untersuchungsstelle"
                    :rules="[rules.rolleRequired]"                    
                    required
                  ></v-select>
                
                <v-alert
                  dense
                  outlined
                  type="info"
                  v-if="this.editedUserId != 0"
                >
                  Wenn Sie das Passwort nicht ändern wollen, lassen Sie die Felder frei.
                </v-alert>

                <!-- Passwort-Felder für neuen User -->
                <div                   
                v-if="this.editedUserId == 0"
                >
                  <v-col cols="12" sm="6">
                    <v-text-field
                      :append-icon="showPasswort ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required, rules.min]"
                      :type="showPasswort ? 'text' : 'password'"
                      name="input-10-2"
                      label="Passwort"
                      hint="Mindestens 8 Zeichen"
                      class="input-group--focused"
                      @click:append="showPasswort = !showPasswort"
                      v-model="passwort"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field
                      :append-icon="showPasswortRepeat ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required, rules.passwortMatch]"
                      :type="showPasswortRepeat ? 'text' : 'password'"
                      name="input-10-2"
                      label="Passwort wiederholen"
                      @click:append="showPasswortRepeat = !showPasswortRepeat"
                      v-model="passwortRepeat"
                    ></v-text-field>
                  </v-col>
                </div>

                <!-- Passwort-Felder für existierenden User -->
                <div v-else>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      :append-icon="showPasswort ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.minIfnotempty]"
                      :type="showPasswort ? 'text' : 'password'"
                      name="input-10-2"
                      label="Passwort"
                      hint="Mindestens 8 Zeichen"
                      class="input-group--focused"
                      @click:append="showPasswort = !showPasswort"
                      v-model="passwort"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field
                      :append-icon="showPasswortRepeat ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.passwortMatch]"
                      :type="showPasswortRepeat ? 'text' : 'password'"
                      name="input-10-2"
                      label="Passwort wiederholen"
                      @click:append="showPasswortRepeat = !showPasswortRepeat"
                      v-model="passwortRepeat"
                    ></v-text-field>
                  </v-col>
                </div>

              </v-form>
            </v-col>

            <v-alert
              v-model="postFailed"
              dense
              outlined
              type="error"
            >          
              Der Vorgang konnte nicht abgeschlossen werden. Es ist folgender Fehler aufgetreten:<br />
              <p v-html="postFailedMsg"></p>
            </v-alert>
    
            <v-alert
              v-model="userEditedSuccess"
              dense
              outlined
              type="success"
            >
              Die neuen Daten wurden übernommen!
            </v-alert>            

            </v-row>
          </v-container>
        </v-card-text>



        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="closeNeuerUser">Abbrechen</v-btn>
          <v-btn color="blue darken-1" text @click="postNewUser">Speichern</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <v-dialog
      v-model="standby"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Einen Moment bitte...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="successUserEdit"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">Erfolg</v-card-title>

        <v-card-text>
          {{ this.successUserEditMsg }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            text
            @click="successUserEdit = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>

  <v-container v-else>
    <h2>Kein Zugriff</h2>
    <router-link to="/login">Loggen Sie sich ein</router-link>
  </v-container>

</template>

<script>
  export default {
    data () {
      return {
        editUserDialog: false,
        editUserDialogHeadline: 'Neuen User anlegen',

        notvalidated: false,
        standby: false,

        name: '',
        email: '',
        rolle: 'Untersuchungsstelle',
        passwort: '',
        passwortRepeat: '',
        zusatzinfo: '',
        editedUserId: 0,

        postFailed: false,
        userEditedSuccess: false,
        postFailedMsg: '',

        successUserEdit: false,
        successUserEditMsg: 'Der Vorgang wurder erfolgreich beendet!',
        search: '',

        users: [],
        userroles: [
          'Untersuchungsstelle', 'Admin'
        ],
        untersuchungsstelle: '',

        headers: [
          { text: 'Typ', value: 'roles_mask', sortable: false },
          { text: 'ID', value: 'id', sortable: true },
          { text: 'Name', value: 'name', sortable: true },
          { text: 'Zusatzinfo', value: 'zusatzinfo', sortable: false },
          { text: 'E-mail', value: 'email', sortable: false },
          { text: '', value: null, sortable: false },
          { text: '', value: null, sortable: false },          
          // { text: 'Registered', value: 'registered', sortable: false },
        ],
        valid: false,
        nameRules: [
          v => !!v || 'Name ist erforderlich',

        ],
        showPasswort: false,
        showPasswortRepeat: false,             

        editedIndex: -1,
        editedItem: {
          name: '',
          rolle: 'Untersuchungsstelle',
          zusatzinfo: ''
        },        
        rules: {
          required: value => !!value && this.editedUserId == 0 || 'Erforderlich!',
          rolleRequired: value => !!value || 'Erforderlich!',
          minIfnotempty: function (v) {
            if (v.length > 0 && v.length < 8) {
              return 'Mindestens 8 Zeichen'
            }
          } ,
          min: v => v.length >= 8 || 'Mindestens 8 Zeichen',
          passwortMatch: value => value == this.passwort || 'Die Passwörter stimmen nicht überein',
        },              
        passwordRules: [
          v => !!v || 'Passwort ist erforderlich',
          v => v.length >= 8 || 'Das Passwort muss mindestens 8 Zeichen haben',
          v => (v || '').indexOf(' ') < 0 || 'Leerzeichen sind nicht erlaubt'
        ],
        passwordRepeatRules: [
          v => !!v || 'Bitte wiederholen Sie das Passwort hier',
          v => v !== this.passwort || 'Die Eingaben stimmen nicht überein'
        ],
        emailRules: [
          v => !!v || 'E-mail ist erforderlich',
          v => /.+@.+/.test(v) || 'Es muss sich um eine gültige E-mail-Adresse handeln',
        ]
      }

    },
    mounted () {
      this.users = this.getUsers()
    },
    methods: {
      newUserDialog() {
        this.editedUserId = 0
        this.name = ''
        this.email = ''
        this.rolle = 'Untersuchungsstelle'
        this.passwort = ''
        this.passwortRepeat = ''
        this.zusatzinfo = ''
        this.editUserDialogHeadline = 'Neuen User anlegen'
        this.editUserDialog = true
        this.userEditedSuccess = false
        this.$refs.editUserForm.resetValidation()

      },
      editUser(id) {
        let userToUse;

        for (var i=0;i<this.users.length;i++) {
          // console.log (this.users[i]);
          let thisUser = this.users[i]
          if (thisUser.id == id) {
            userToUse = thisUser
            break
          }
        }
        console.log (userToUse)

        if (typeof userToUse.id === 'undefined') {
          return false;
        }

        this.editedUserId = id

        this.name = userToUse.name
        this.email = userToUse.email
        if (userToUse.roles_mask == 1) {
          this.rolle = 'Admin'
        }
        else {
          this.rolle = 'Untersuchungsstelle'
        }
        
        this.passwort = ''
        this.passwortRepeat = ''
        this.zusatzinfo = userToUse.zusatzinfo
        this.editUserDialogHeadline = 'User bearbeiten'
        this.editUserDialog = true
        this.userEditedSuccess = false
        this.$refs.editUserForm.resetValidation()

      },
      postNewUser () {
        
        if (!this.$refs.editUserForm.validate()) {
          this.notvalidated = true
          return false
        }

        let thisUser = this.$root.getUser()

        let _this = this
        _this.standby = true

        let data = {
          'userId': thisUser.userId,
          'authkey': thisUser.authkey,
          'editedUserId': _this.editedUserId,
          'name': _this.name,
          'email': _this.email,
          'rolle': _this.rolle,
          'passwort': _this.passwort,
          'zusatzinfo': _this.zusatzinfo,
        }
        data = JSON.stringify(data);
        let endpoint;
        if (_this.editedUserId == 0) {
          endpoint = '/users/new/'
        }
        else {
          endpoint = '/users/edit/' + _this.editedUserId
        }

        var xhr = new XMLHttpRequest();
        xhr.open("POST", this.$root.gefspotAPI + endpoint, true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
        xhr.send("data=" + data);
        xhr.onload = function() {
          _this.standby = false
          // console.log(this.responseText);
          var response = JSON.parse(this.responseText)
          // console.log (response)

          if (response.status == 'error') {
            _this.postFailed = true
            _this.postFailedMsg = response.msg
          }
          else if (response.status == 'success') {
            _this.closeNeuerUser()
            if (_this.editedUserId == 0) {
              _this.successUserEditMsg = 'Der User wurde erfolgreich angelegt!'
            } else {
              _this.successUserEditMsg = 'Die Änderungen wurden gespeichert!'
            }
            _this.successUserEdit = true            
            _this.getUsers()
          }

        };

      },
      getUsers () {

        let thisUser = this.$root.getUser()

        let _this = this
        _this.standby = true

        let data = {
          'userId': thisUser.userId,
          'authkey': thisUser.authkey
        }
        data = JSON.stringify(data);

        var xhr = new XMLHttpRequest();
        xhr.open("POST", this.$root.gefspotAPI + "/users/all/", true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
        xhr.send("data=" + data);
        xhr.onload = function() {
          _this.standby = false
          var response = JSON.parse(this.responseText)
          console.log (response)

          if (response.status == 'error') {
            _this.postFailed = true
            _this.postFailedMsg = response.msg
          }
          else if (response.status == 'success') {
            _this.users = response.result;
          }

        };

      },
      closeNeuerUser () {
        this.notvalidated = false
        this.$refs.editUserForm.reset()
        this.rolle = 'Untersuchungsstelle' 
        this.editUserDialog = false
        this.postFailed = false
      },/*   
      getDataFromApi () {
        this.loading = true
        return new Promise((resolve) => {
          const { sortBy, descending, page, rowsPerPage } = this.pagination

          let items = this.getTransaktionen()
          const total = items.length

          if (this.pagination.sortBy) {
            items = items.sort((a, b) => {
              const sortA = a[sortBy]
              const sortB = b[sortBy]

              if (descending) {
                if (sortA < sortB) return 1
                if (sortA > sortB) return -1
                return 0
              } else {
                if (sortA < sortB) return -1
                if (sortA > sortB) return 1
                return 0
              }
            })
          }

          if (rowsPerPage > 0) {
            items = items.slice((page - 1) * rowsPerPage, page * rowsPerPage)
          }

          setTimeout(() => {
            this.loading = false
            resolve({
              items,
              total
            })
          }, 1000)
        })
      },
      */
      deleteItem (id) {

        var answer = confirm('Sind Sie sicher, dass Sie diesen User löschen wollen? Sie können diesen Vorgang nicht mehr rückgängig machen.')
        var _this = this
        if (answer == false) {
          return false;
        }

        let thisUser = this.$root.getUser()

        _this.standby = true

        let data = {
          'userId': thisUser.userId,
          'authkey': thisUser.authkey
        }
        data = JSON.stringify(data);

        var xhr = new XMLHttpRequest();
        xhr.open("POST", this.$root.gefspotAPI + "/users/delete/" + id, true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
        xhr.send("data=" + data);
        xhr.onload = function() {
          _this.standby = false
          var response = JSON.parse(this.responseText)
          console.log (response)
          this.users = _this.getUsers()
          if (response.status == 'success') {
            alert('Der User wurde gelöscht!')
          }
        };

      },
      showUserRolle (roleID) {
        if (roleID == 1) {
          return 'A';
        }
        else {
          return 'U';
        }
      },
      colorUserRolle (roleID) {
        if (roleID == 1) {
          return 'red';
        }
        else {
          return 'lightgrey'
        }
      },/*
      customFilter(items, filters, filter, headers) {
        // Init the filter class.
        const cf = new this.$MultiFilters(items, filters, filter, headers);
/*
        cf.registerFilter('search', function (searchWord, items) {
          if (searchWord.trim() === '') return items;

          return items.filter(item => {
            return item.name.toLowerCase().includes(searchWord.toLowerCase());
          }, searchWord);

        });*//*
        cf.registerFilter('added_by', function (added_by, items) {
          if (added_by.trim() === '') return items;

          return items.filter(item => {
            return item.added_by.toLowerCase() === added_by.toLowerCase();
          }, added_by);

        });

        cf.registerFilter('country', function (country, items) {
          if (country.trim() === '') return items;

          return items.filter(item => {
            return item.country.toLowerCase() === country.toLowerCase();
          }, country);

        });

        cf.registerFilter('sample', function (sample, items) {
          if (sample.trim() === '') return items;

          return items.filter(item => {
            return item.samplename.toLowerCase() === sample.toLowerCase();
          }, sample);

        });
        cf.registerFilter('filterAuthor', function (sample, items) {
          if (sample.trim() === '') return items;

          return items.filter(item => {
            return item.samplename.toLowerCase() === sample.toLowerCase();
          }, sample);

        });

        // Its time to run all created filters.
        // Will be executed in the order thay were defined.
        return cf.runFilters();
      },
*/
    }
  }
</script>

<style>
  .gump-error-message {
    display:block;
  }
  .v-btn {
    margin-right: 16px;
  }  
</style>