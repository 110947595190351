<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      dark
      app
    >
      <v-list dense>

        <router-link to="/" v-if="this.$root.loggedin">
          <v-list-item link>
            <v-list-item-action>
              <v-icon>mdi-account</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Personen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>

        <router-link to="/login" v-if="!this.$root.loggedin">
          <v-list-item link>
            <v-list-item-action>
              <v-icon>mdi-login-variant</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Login</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>

        <router-link to="/personen-editieren" v-if="this.$root.adminonly && this.$root.loggedin">
          <v-list-item link>
            <v-list-item-action>
              <v-icon>mdi-account-edit-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Personen verwalten</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>

        <router-link to="/untersuchungen" v-if="this.$root.loggedin">
          <v-list-item link>
            <v-list-item-action>
              <v-icon>mdi-clipboard-check-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Untersuchungen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>

        <router-link to="/user" v-if="this.$root.adminonly && this.$root.loggedin">
          <v-list-item link>
            <v-list-item-action>
              <v-icon>mdi-account-supervisor-circle</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>User</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>

        <router-link to="/settings" v-if="this.$root.adminonly && this.$root.loggedin">
          <v-list-item link>
            <v-list-item-action>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Settings</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>

        <router-link to="/logs" v-if="this.$root.adminonly && this.$root.loggedin">
          <v-list-item link>
            <v-list-item-action>
              <v-icon>mdi-clipboard-clock-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Logs</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>

        <router-link to="/account" v-if="this.$root.loggedin">
          <v-list-item link>
            <v-list-item-action>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Mein Account</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>

        <router-link to="/impressum">
          <v-list-item link>
            <v-list-item-action>
              <v-icon>mdi-scale-balance</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Impressum / Datenschutz</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>

      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      v-bind:color="getBrandColor()"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title>{{brand}}</v-toolbar-title>
           <!--<v-toolbar-side-icon>
            <v-img class="mr-3" src="@/assets/sportlandniederoesterreich-logo-rgb-png.png" height="30px" width="40px"> 
            </v-img>
            
     </v-toolbar-side-icon>-->
     
      <v-spacer></v-spacer>
      <v-img :src="require('@/assets/sportlandniederoesterreich-logo-rgb-png.png')"  height="100px" width="100px" contain="true" style="flex:unset;" ></v-img>
    </v-app-bar>

    <v-content>
      <v-container class="fill-height"
        fluid>
        
        <v-layout class="justify-start align-start">
          <router-view></router-view>
        </v-layout>

      </v-container>
    </v-content>

    <!--
    <v-content>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col class="text-center">

          </v-col>
        </v-row>
      </v-container>
    </v-content>-->
    <!--<v-footer
      color="indigo"
      app
    >
      <span class="white--text">&copy; 2019</span>
    </v-footer>-->
  </v-app>
</template>

<script>
import colors from 'vuetify/lib/util/colors'

export default {
  name: 'app',
  data: () => ({
    drawer: true,
    brand: null,
    brandcolor: null,
    color_primary: null,
    color_secondary: null
  }),
  methods: {
    getBrandColor () {
      return this.brandcolor;
    },
    getBranding() {
      return this.$http.get(this.$root.gefspotAPI + "/getbranding")
      .then(response => {
        // console.log (response)
        // console.log (response.data)
        // response.data.branding = "3" // DEBUG
        switch (response.data.branding) {
          case "1":
            this.brand      = "GEFSPOT - Leistungssport Austria"
            this.brandcolor = "red accent-4"
            this.colorcode_primary    = colors.red.darken1
            this.colorcode_secondary  = colors.red.lighten4           
            break
          case "2":
            this.brand      = "Sportmedizinische Grunduntersuchungen – SPORTLAND Niederösterreich"
            this.brandcolor = "noegelb"
            this.colorcode_primary    = colors.blue.darken3                       
            this.colorcode_secondary  = colors.blue.lighten4
            break
          default:
            this.brand      = "Sportmedizinische Untersuchungen"
            this.brandcolor = "teal darken-1"
            this.colorcode_primary    = colors.teal.darken1
            this.colorcode_secondary  = colors.teal.lighten4                  
        }
        
        this.$vuetify.theme.themes.light.primary = this.colorcode_primary
        this.$vuetify.theme.themes.light.secondary = this.colorcode_secondary        
      });

    }
  },
  mounted () {
    this.$root.checkIfLoggedIn()
    this.getBranding();
  },  
  props: {
    source: String
  }
}
</script>

<style>
    nav.v-navigation-drawer a { text-decoration: none; }
    .v-toolbar__title { color: #fff; }
    .v-application--is-ltr .v-toolbar__content > .v-btn.v-btn--icon:first-child, .v-application--is-ltr .v-toolbar__extension > .v-btn.v-btn--icon:first-child { margin-bottom: 0;}
</style>