<template>

  <v-container v-if="this.$root.loggedin">

    <h2>Untersuchungen</h2>
    <br />

    <v-card class="mb-5 pb-3">
      <v-card-text>

        <div v-if="this.$root.adminonly">
          Sie sehen hier alle Untersuchungen, die durchgeführt wurden. Eine Untersuchungsstelle kann hier überprüfen, welche Untersuchungen sie durchgeführt hat.
        </div>
        
        <div v-else>
          Sie sehen hier die Untersuchungen, die von Ihnen durchgeführt wurden.
        </div>
      </v-card-text>
    </v-card>


    <v-container row wrap class="justify-md-space-between">

  
      <v-row align="center">

        <v-col cols="4">
          <v-select
            v-model="periodselect"
            :items="perioditems"
            v-on:change="changeUsers()"
            item-text="state"
            item-value="abbr"
            label="Select"
            persistent-hint
            return-object
            single-line
          ></v-select>
        </v-col>

        <v-col cols="4">
          <v-select
            v-model="verbaendeselect"
            :items="verbaendeitems"
            v-on:change="changeUsers()"
            item-text="state"
            item-value="abbr"
            label="Select"
            persistent-hint
            return-object
            single-line
          ></v-select>
        </v-col>

        <v-col cols="4">
          <v-text-field
            v-model="search"
            prepend-icon="mdi-magnify"
            label="Suche"
            filled
            clearable
          ></v-text-field>
        </v-col>

      </v-row>

    </v-container>

    <v-data-table
      v-bind:headers="headers"
      v-bind:items="users"
      v-bind:search="search"
      :footer-props="{'items-per-page-options':[10, 25, 50, 100, -1]}"      
        sort-by="name"
      :loading="standby"
      class="elevation-1"
    >

      <template v-slot:item="row">
          <tr class="">
            <td class="mobile-full-width">{{row.item.periode}}</td>
            <td class="mobile-full-width">{{row.item.vorname}}</td>
            <td class="mobile-full-width">{{row.item.nachname}}</td>
            <td class="mobile-full-width">{{row.item.geburtsdatum}}</td>
            <td class="mobile-full-width">{{row.item.verband}}</td>
            <td class="mobile-full-width">{{formatDate(row.item.zeichnungsdatum)}}</td>
            <td class="mobile-full-width">{{row.item.untersuchungsstelle}}</td>
            <td class="mobile-full-width">{{row.item.userId}}</td>
          </tr>
      </template>      

    </v-data-table>

    <v-container>
        <br />
        <v-row align="center">
        <download-csv
          :data = "users">
          <v-btn 
            dark
            color="grey darken-1"
            >
            <v-icon left>mdi-file-download</v-icon> CSV Export Filter Jahr/Verband
          </v-btn>
        </download-csv>

        <download-csv
          :data = "allusers">
          <v-btn 
            dark
            color="grey darken-1"
            >
            <v-icon left>mdi-file-download</v-icon> CSV Export - alle Untersuchungen
          </v-btn>
        </download-csv>
      </v-row>
    </v-container>  

    <v-dialog
      v-model="standby"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Einen Moment bitte...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="successDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">Erfolg</v-card-title>

        <v-card-text>
          {{ this.successDialogMsg }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            text
            @click="successDialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>

  <v-container v-else>
    <h2>Kein Zugriff</h2>
    <router-link to="/login">Loggen Sie sich ein</router-link>
  </v-container>

</template>

<script>
  export default {
    data () {
      return {
        uploadCSVDialog: false,
        csvfileinput: '',
        availablePeriods: [],
        csvfileperiode: '',

        editUserDialog: false,
        editUserDialogHeadline: 'Neuen User anlegen',

        notvalidated: false,
        standby: false,

        name: '',
        email: '',
        rolle: 'Untersuchungsstelle',
        passwort: '',
        passwortRepeat: '',
        zusatzinfo: '',
        editedUserId: 0,

        postFailed: false,
        userEditedSuccess: false,
        postFailedMsg: '',

        successDialog: false,
        successDialogMsg: 'Der Vorgang wurde erfolgreich beendet!',
        search: '',
        
        users: [],
        allusers: [],

        pagination: {},
        headers: [
          { text: 'Periode', value: 'periode', sortable: true },
          { text: 'Vorname', value: 'vorname', sortable: true },
          { text: 'Nachname', value: 'nachname', sortable: true },
          { text: 'Geburtsdatum', value: 'geburtsdatum', sortable: true },
          { text: 'Verband', value: 'verband', sortable: true },
          { text: 'Datum', value: 'zeichnungsdatum', sortable: true },
          { text: 'Stelle', value: 'reversedMessage', sortable: true },
          { text: 'Stelle ID', value: 'userId', sortable: true },
        ],
        
        periodselect: [],
        perioditems: [],     

        verbaendeselect: [],
        verbaendeitems: [],
        
        valid: false
      }

    },
    mounted () {
      this.$root.checkIfLoggedIn()

      this.getAvailableYears()
        .then(() => {
          this.users = this.getUsers();
        })
        .catch(e => {
          this.errors.push(e)
        });
      
    },
    methods: {
      formatDate(date) {
        date = date.substring(0, date.indexOf(' '));
        // return new Date(date).toLocaleString();
        return date;
      },
      getAvailableYears() {
        return this.$http.get(this.$root.gefspotAPI + "/available-periods")
        .then(response => {
          // console.log (response)
          // console.log (response.data)
          let yearsArray = response.data; 
          this.availablePeriods = yearsArray
          this.selectedYear = yearsArray[1] // das aktuelle Jahr

          // hier werden die Jahre für den Filter eingetragen
          yearsArray.unshift("Alle Perioden");
          this.perioditems = yearsArray
          this.periodselect = yearsArray[0]
        });
      },
      changeUsers() {
        let _this = this
        let newUsers = []
        console.log ('Gewählte Periode: ' +  _this.periodselect)
        console.log ('GEwählter verband: ' +  _this.verbaendeselect)

        if (_this.periodselect == 'Alle Perioden' && _this.verbaendeselect == 'Alle Verbände') {
          _this.users = _this.allusers
          return false;
        }

        for (let i=0;i<_this.allusers.length;i++) {
          console.log ('verbaend: ' + _this.allusers[i].periode);
          console.log ('verbaendeselect: ' + _this.verbaendeselect);

          // wenn alle verbände und bestimmte periode
          if (_this.verbaendeselect == 'Alle Verbände' && _this.periodselect == _this.allusers[i].periode) {
            newUsers.push(_this.allusers[i])
          } 
          // wenn bestimmte verbände und alle perioden
          else if (_this.verbaendeselect == _this.allusers[i].verband && _this.periodselect == 'Alle Perioden') {
            newUsers.push(_this.allusers[i])
          }
          
          // wenn bestimmte verbände und bestimmte perioden
          else if (_this.verbaendeselect == _this.allusers[i].verband && _this.periodselect == _this.allusers[i].periode) {
            newUsers.push(_this.allusers[i])
          }
          
        }

        _this.users = newUsers

      },
      getUsers () {

        let thisUser = this.$root.getUser()

        let _this = this
        _this.standby = true
        console.log (_this.availablePeriods);
        if (_this.selectedYear == 0)  {
          _this.selectedYear = _this.availablePeriods[1] 
        }       

        let data = {
          'userId': thisUser.userId,
          'authkey': thisUser.authkey
        }
        data = JSON.stringify(data);

        var xhr = new XMLHttpRequest();
        xhr.open("POST", this.$root.gefspotAPI + "/untersuchungen/alle-untersuchungen/", true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
        xhr.send("data=" + data);
        xhr.onload = function() {
          _this.standby = false
          var response = JSON.parse(this.responseText)
          console.log (response)

          if (response.status == 'error') {
            _this.postFailed = true
            _this.postFailedMsg = response.msg
          }
          else if (response.status == 'success') {
            _this.users = response.result;
            _this.allusers = response.result;
            _this.populateVerbaende();
          }

        };

      },
      populateVerbaende() {
        let _this = this
        let anzahlUser = _this.users.length
        // console.log ("anzahlUser: " + anzahlUser)

        let verbaendeArray = [];

        for (let i=0; i<anzahlUser; i++) {
          // console.log (_this.users[i]);
          let thisUntersuchung = _this.users[i]
          // console.log ("verband: " + thisUntersuchung.verband);
          // console.log ("verbaendeArray.length: " + verbaendeArray.length);
          
          if (verbaendeArray.length == 0) {
            verbaendeArray.push(thisUntersuchung.verband);
          }

          if(verbaendeArray.indexOf(_this.users[i].verband) !== -1) {
            continue;
          } else {
            verbaendeArray.push(thisUntersuchung.verband);
          }

          verbaendeArray.unshift("Alle Verbände");
          _this.verbaendeitems = verbaendeArray
          _this.verbaendeselect = verbaendeArray[0] // Alle
          // console.log (verbaendeArray)
        }
             
      },
    },
    computed: {
      // a computed getter
      reversedMessage: function () {
        // `this` points to the vm instance
        return this.item.zeichnungsdatum + ' hugenotten'
      }
    }
  }
</script>

<style>
  .v-btn {
    margin-right: 16px;
  }  
  tr:nth-child(even) {
    background-color: #f8f8f8;
  }
  @media only screen and (max-width: 768px) {
    .mobile-full-width {
      display: flex;
      width: 100% !important;
      margin-left: 16px !important;
      border-top: 0;
      padding: 5px 16px !important;
    }
  }
</style>