<template>

  <v-container v-if="this.$root.adminonly && this.$root.loggedin">

    <h2>Personen verwalten</h2>
    <br />

    <v-card class="mb-5 pb-3">
      <v-card-text class="text-sm-left">
        Sie können hier Personen via CSV importieren oder Personen löschen. Personen, an denen bereits eine Untersuchung durchgeführt wurde, sind mit einem roten U gekennzeichnet.<br /><br />
        <b>Info:</b> Wenn Sie eine Person löschen, die bereits eine Untersuchung hatte, bleiben die Untersuchungsdaten erhalten!
        <br />

        <!--<v-btn x-large color="primary" dark @click.stop="newUserDialog()" v-if="this.$root.adminonly">Neue Person eintragen</v-btn>-->
        <!--
        <v-btn x-large color="primary" dark @click.stop="getUsers()">getUsers</v-btn>
        <v-btn color="secondary" v-on:click="this.$root.checkStatus">checkStatus</v-btn>
        <v-btn color="secondary" v-on:click="this.$root.hellouserauth">hellouserauth</v-btn>
        -->

      </v-card-text>
    </v-card>


    <v-container row wrap class="justify-md-space-between">

      <v-row align="center">

        <v-col cols="4">
          <v-select
            v-model="periodselect"
            :items="perioditems"
            v-on:change="changeUsers()"
            item-text="state"
            item-value="abbr"
            label="Select"
            persistent-hint
            return-object
            single-line
          ></v-select>
        </v-col>

        <v-col cols="4">
          <v-select
            v-model="verbaendeselect"
            :items="verbaendeitems"
            v-on:change="changeUsers()"
            item-text="state"
            item-value="abbr"
            label="Select"
            persistent-hint
            return-object
            single-line
          ></v-select>
        </v-col>

        <v-col cols="4">
          <v-text-field
            v-model="search"
            prepend-icon="mdi-magnify"
            label="Suche"
            filled
            clearable
          ></v-text-field>
        </v-col>

      </v-row>

    </v-container>


    <v-data-table
      :headers="headers"
      :items="users"
      v-bind:search="search"
      :footer-props="{'items-per-page-options':[10, 25, 50, 100, -1]}"
      sort-by="name"
      :loading="standby"
      class="elevation-1"
    >
      <template v-slot:item="row">
          <tr class="">
            <td><v-chip color="red" v-if="row.item.zeichnungsdatum" dark >U</v-chip></td>
            <td class="mobile-full-width">{{row.item.periode}}</td>
            <td class="mobile-full-width">{{row.item.id}}</td>
            <td class="mobile-full-width">{{row.item.vorname}}</td>
            <td class="mobile-full-width">{{row.item.nachname}}</td>
            <td class="mobile-full-width">{{row.item.geburtsdatum}}</td>
            <td class="mobile-full-width">{{row.item.verband}}</td>
            <td class="mobile-full-width">
              <v-icon
                class="mr-2"
                @click="deleteItem(row.item.id)"
              >
                mdi-delete
              </v-icon>
            </td>
            <td>
            </td>         
          </tr>
      </template>      

    </v-data-table>

      
    <v-container>
      <br />
      <v-row align="center">
        
        <v-btn color="primary" dark @click.stop="uploadCSVDialog = true" v-if="this.$root.adminonly">
          <v-icon left>mdi-upload</v-icon> Personen CSV importieren
        </v-btn>

        <download-csv
          :data = "users">
          <v-btn 
            dark
            color="grey darken-1"
            >
            <v-icon left>mdi-file-download</v-icon> CSV Export Filter Jahr/Verband
          </v-btn>
        </download-csv>

        <download-csv
          :data   = "users">
          <v-btn 
            dark
            color="grey darken-1"
            >
            <v-icon left>mdi-file-download</v-icon> CSV Export - vollständig
          </v-btn>
        </download-csv>

        <v-btn color="secondary" dark @click.stop="bulkDeleteDialogOpen" v-if="this.$root.adminonly">
          <v-icon left>mdi-delete</v-icon> Personen Bulk Delete
        </v-btn>

      </v-row>
    </v-container>


    <v-dialog v-model="uploadCSVDialog" persistent max-width="600px" v-if="this.$root.adminonly">
      <v-card>

        <v-card-title>
          <span class="headline">Personen CSV importieren</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>

              <v-col cols="12">
                <v-form ref="uploadCSVFrom">

                  <v-alert
                    dense
                    outlined
                    type="info"
                  >
                    <h3>Bitte beachten Sie:</h3><br />
                    <ul>

                      <li>
                        Das <b>CSV muss vier Spalten haben</b>. In den Spalten muss der richtige Wert stehen:<br />
                        <ul>
                          <li>Spalte 1: Verband</li>
                          <li>Spalte 2: Vorname</li>
                          <li>Spalte 3: Nachname</li>
                          <li>Spalte 4: Geburtsdatum</li>
                        </ul>
                      </li>
  
                      <li><b>Entfernen Sie gegebenenfalls die Spaltenbeschriftung</b> aus der ersten Zeile: es werden die Zeilen mit Eintrag "Verband" und "Column1" in der ersten Spalte übersprungen, ansonsten werden alle Zeilen importiert!</li>

                      <li>Der Import <b>muss einer Periode (=Kalenderjahr) zugeordnet</b> werden.</li>

                      <li>Ist ein <b>identer Eintrag</b> (Name und Geburtsdatum) für eine Periode bereits in der Datenbank vorhanden, wird er <b>nicht importiert</b>.</li>

                    </ul>
                    <br />
                  </v-alert>

                  <br />
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-select
                      v-model="csvfileperiode"
                      :items="availablePeriods"
                      :rules="[rules.required]"
                      hint="Periode muss ausgewählt sein"                    
                      filled
                      label="Periode auswählen"
                      required
                    ></v-select>
                  </v-col>
                  <br />

                  <v-file-input
                    v-model="csvfileinput"
                    accept=".csv"
                    label="CSV Datei auswählen"
                    prepend-icon="mdi-file"
                    clearable
                    :rules="[rules.required]"
                    required
                    hint="Datei muss ausgewählt sein"
                  ></v-file-input>

                </v-form>
              </v-col>

              <v-alert
                v-model="importFailed"
                dense
                outlined
                type="error"
              >          
                Der Vorgang konnte nicht abgeschlossen werden. Es ist folgender Fehler aufgetreten:<br />
                <p v-html="importFailedMsg"></p>
              </v-alert>
      
              <v-alert
                v-model="importSuccess"
                dense
                outlined
                type="success"
              >
                {{this.importSuccessMsg}}
              </v-alert>

            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="uploadCSVDialogClose">Abbrechen</v-btn>
          <v-btn color="primary" text @click="uploadCSV">Importieren</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>


    <v-dialog v-model="bulkDeleteDialog" persistent max-width="600px" v-if="this.$root.adminonly">
      <v-card>

        <v-card-title>
          <span class="headline">Personen Bulk Delete</span>
        </v-card-title>
        
        <br />

        <v-card-text>
          <v-container>
            <v-row>

              <v-alert
                dense
                outlined
                type="info"
              >
                <h3>Bitte beachten Sie:</h3><br />
                <ul>

                  <li>
                    Sie können hier alle Personen löschen, die aktuell über die Filter Periode und Verband ausgewählt sind. Die Freitextsuche ist hier nicht berücksichtigt.
                  </li>

                  <li>Bereits erfolgte Untersuchungen werden nicht gelöscht.</li>

                  <li>Dieser Vorgang kann nicht rückgängig gemacht werden!</li>

                </ul>
                <br />
              </v-alert>

              <br />

              <v-alert
                dense
                outlined
                type="error"
              >          
                Sie haben <b>{{anzahlPersoneDelete}} Person(en)</b> aus Periode <b>"{{periodselect}}"</b> für Verband <b>"{{verbaendeselect}}"</b> zum Löschen vorgesehen. <b>Ist das richtig?</b><br /><br />            
              </v-alert>
      
              <v-alert
                v-model="bulkdeleteSuccess"
                dense
                outlined
                type="success"
              >
                {{this.bulkdeleteSuccessMsg}}
              </v-alert>

            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="bulkDeleteDialogClose">Abbrechen</v-btn>
          <v-btn color="red" text @click="confirmedBulkDelete">Löschen</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>


    <v-dialog
      v-model="standby"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Einen Moment bitte...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="successDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">Erfolg</v-card-title>

        <v-card-text>
          {{ this.successDialogMsg }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            text
            @click="successDialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>

  <v-container v-else>
    <h2>Kein Zugriff</h2>
    <router-link to="/login">Loggen Sie sich ein</router-link>
  </v-container>

</template>

<script>
  export default {
    data () {
      return {
        uploadCSVDialog: false,
        csvfileinput: '',
        availablePeriods: [],
        csvfileperiode: '',

        editUserDialog: false,
        editUserDialogHeadline: 'Neuen User anlegen',

        notvalidated: false,
        standby: false,

        untersuchungDialog: false,

        name: '',
        email: '',
        rolle: 'Untersuchungsstelle',
        passwort: '',
        passwortRepeat: '',
        zusatzinfo: '',
        editedUserId: 0,

        postFailed: false,
        userEditedSuccess: false,
        postFailedMsg: '',

        importFailed: false,
        importFailedMsg: '',
        importSuccess: false,      
        importSuccessMsg: '',
        bulkdeleteSuccess: false,      
        bulkdeleteSuccessMsg: '',

        successDialog: false,
        successDialogMsg: 'Der Vorgang wurde erfolgreich beendet!',
        search: '',
        
        users: [],
        userroles: [
          'Untersuchungsstelle', 'Admin'
        ],
        allusers: [],        

        headers: [
          { text: '', value: null, sortable: false },
          { text: 'Periode', value: 'periode', sortable: true },
          { text: 'ID', value: 'id', sortable: true },
          { text: 'Vorname', value: 'vorname', sortable: true },
          { text: 'Nachname', value: 'nachname', sortable: true },
          { text: 'Geburtsdatum', value: 'geburtsdatum', sortable: true },
          { text: 'Verband', value: 'verband', sortable: false },
          { text: '', value: null, sortable: false },
          // { text: 'Registered', value: 'registered', sortable: false },
        ],
        valid: false,

        editedIndex: -1,
        editedItem: {
          name: '',
          rolle: 'Untersuchungsstelle',
          zusatzinfo: ''
        },        
        rules: {
          required: value => !!value && this.editedUserId == 0 || 'Erforderlich!',
        },
        
        periodselect: [],
        perioditems: [],     

        verbaendeselect: [],
        verbaendeitems: [],

        bulkDeleteDialog: false,
        periodedelete: '',
        verbanddelete: '',
        anzahlPersoneDelete: '',
      }

    },
    mounted () {
      this.$root.checkIfLoggedIn()
      this.$root.thisPageAdminOnly()
      this.getAvailableYears()
        .then(() => {
          this.users = this.getUsers()
        })
        .catch(e => {
          this.errors.push(e)
        });      
    },
    methods: {
      uploadCSVDialogClose() {
        this.importSuccess = false
        this.csvfileinput = ''
        this.uploadCSVDialog = false
      },      
      bulkDeleteDialogOpen() {
        this.bulkDeleteDialog = true
        let selectedUsers = this.users
        this.anzahlPersoneDelete = selectedUsers.length
      },      
      bulkDeleteDialogClose() {
        this.bulkdeleteSuccess = false
        this.bulkDeleteDialog = false
      },
      confirmedBulkDelete() {
        let _this = this
        _this.standby = true

        let thisUser = this.$root.getUser()

        let data = {
          'userId': thisUser.userId,
          'authkey': thisUser.authkey,
          'personenToDelete': _this.users
        }
        data = JSON.stringify(data);

        var formData = new FormData();
        formData.append("data", data);

        var xhr = new XMLHttpRequest();
        xhr.open("POST", this.$root.gefspotAPI + "/personen/bulkdelete/", true);
        // xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");

        xhr.send(formData);

        xhr.onload = function() {
          _this.standby = false
          console.log (this)
          var response = JSON.parse(this.responseText)
          console.log (response)
          this.users = _this.getUsers()

          if (response.status == 'success') {
            _this.bulkdeleteSuccess = true
            _this.bulkdeleteSuccessMsg = response.msg
          }
          /*else {
            _this.importFailed = true
            _this.importFailedMsg = 'Das CSV konnte nicht verarbeitet werden. Bitte überprüfen Sie es auf Vollständigkeit (Leerzeilen, Verbände, etc.)'
          }*/
        }
      },
      uploadCSV() {
        if (!this.$refs.uploadCSVFrom.validate()) {
          return false
        }
        let _this = this
        _this.standby = true

        let thisUser = this.$root.getUser()

        let data = {
          'userId': thisUser.userId,
          'authkey': thisUser.authkey,
          'periode': this.csvfileperiode
        }
        data = JSON.stringify(data);

        var formData = new FormData();
        formData.append("csv", this.csvfileinput);
        formData.append("data", data);

        var xhr = new XMLHttpRequest();
        xhr.open("POST", this.$root.gefspotAPI + "/personen/importcsv/", true);
        // xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");

        xhr.send(formData);

        xhr.onload = function() {
          _this.standby = false
          console.log (this)
          var response = JSON.parse(this.responseText)
          console.log (response)
          this.users = _this.getUsers()
          if (response.status == 'success') {
            _this.importSuccess = true
            _this.importSuccessMsg = response.msg
          }
          /*else {
            _this.importFailed = true
            _this.importFailedMsg = 'Das CSV konnte nicht verarbeitet werden. Bitte überprüfen Sie es auf Vollständigkeit (Leerzeilen, Verbände, etc.)'
          }*/
        };
      },
      getAvailableYears() {
        return this.$http.get(this.$root.gefspotAPI + "/available-periods")
        .then(response => {
          // console.log (response)
          // console.log (response.data)
          let yearsArray = response.data; 
          this.availablePeriods = yearsArray
          this.selectedYear = yearsArray[1] // das aktuelle Jahr

          // hier werden die Jahre für den Filter eingetragen
          yearsArray.unshift("Alle Perioden");
          this.perioditems = yearsArray
          if (this.periodselect == '') {
            this.periodselect = yearsArray[0]  
          }
          
        });
      },
      openUntersuchungDialog (id) {
        this.untersuchungDialog = true
        
        let userToUse

        for (var i=0;i<this.users.length;i++) {
          let thisUser = this.users[i]
          if (thisUser.id == id) {
            userToUse = thisUser
            break
          }
        }
        console.log (userToUse)
        this.untersuchtePerson = userToUse

      },
      getUsers () {

        let thisUser = this.$root.getUser()

        let _this = this
        _this.standby = true

        let data = {
          'userId': thisUser.userId,
          'authkey': thisUser.authkey
        }
        data = JSON.stringify(data);

        var xhr = new XMLHttpRequest();
        xhr.open("POST", this.$root.gefspotAPI + "/personen/all-personen-edit/", true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
        xhr.send("data=" + data);
        xhr.onload = function() {
          _this.standby = false
          var response = JSON.parse(this.responseText)
          console.log (response)

          if (response.status == 'error') {
            _this.postFailed = true
            _this.postFailedMsg = response.msg
          }
          else if (response.status == 'success') {
            _this.users = response.result;
            _this.allusers = response.result;
            _this.populateVerbaende();
            _this.changeUsers();   
          }

        };

      },
      deleteItem (id) {

        var answer = confirm('Sind Sie sicher, dass Sie diese Person löschen wollen? Sie können diesen Vorgang nicht mehr rückgängig machen!')
        var _this = this
        if (answer == false) {
          return false;
        }

        let thisUser = this.$root.getUser()

        _this.standby = true

        let data = {
          'userId': thisUser.userId,
          'authkey': thisUser.authkey
        }
        data = JSON.stringify(data);

        var xhr = new XMLHttpRequest();
        xhr.open("POST", this.$root.gefspotAPI + "/personen/delete/" + id, true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
        xhr.send("data=" + data);
        xhr.onload = function() {
          _this.standby = false
          var response = JSON.parse(this.responseText)
          console.log (response)
          this.users = _this.getUsers()
          if (response.status == 'success') {
            alert('Der User wurde gelöscht!')
          }
        };

      },

      changeUsers() {
        let _this = this
        let newUsers = []
        console.log ('Gewählte Periode: ' +  _this.periodselect)
        console.log ('GEwählter verband: ' +  _this.verbaendeselect)

        if (_this.periodselect == 'Alle Perioden' && _this.verbaendeselect == 'Alle Verbände') {
          _this.users = _this.allusers
          return false;
        }

        for (let i=0;i<_this.allusers.length;i++) {
          console.log ('verbaend: ' + _this.allusers[i].periode);
          console.log ('verbaendeselect: ' + _this.verbaendeselect);

          // wenn alle verbände und bestimmte periode
          if (_this.verbaendeselect == 'Alle Verbände' && _this.periodselect == _this.allusers[i].periode) {
            newUsers.push(_this.allusers[i])
          } 
          // wenn bestimmte verbände und alle perioden
          else if (_this.verbaendeselect == _this.allusers[i].verband && _this.periodselect == 'Alle Perioden') {
            newUsers.push(_this.allusers[i])
          }
          
          // wenn bestimmte verbände und bestimmte perioden
          else if (_this.verbaendeselect == _this.allusers[i].verband && _this.periodselect == _this.allusers[i].periode) {
            newUsers.push(_this.allusers[i])
          }
          
        }

        _this.users = newUsers

      },
      populateVerbaende() {
        let _this = this
        let anzahlUser = _this.users.length

        let verbaendeArray = [];

        for (let i=0; i<anzahlUser; i++) {
          // console.log (_this.users[i]);
          let thisUntersuchung = _this.users[i]
          // console.log ("verband: " + thisUntersuchung.verband);
          // console.log ("verbaendeArray.length: " + verbaendeArray.length);
          
          if (verbaendeArray.length == 0) {
            verbaendeArray.push(thisUntersuchung.verband);
          }

          if(verbaendeArray.indexOf(_this.users[i].verband) !== -1) {
            continue;
          } else {
            verbaendeArray.push(thisUntersuchung.verband);
          }

        }

        verbaendeArray.unshift("Alle Verbände");
        _this.verbaendeitems = verbaendeArray
        console.log (_this.verbaendeitems)

        // wir überprüfen, ob gewünscht ist, dass die Verbände erneut eingebaut werden
        if (_this.verbaendeselect == '') {
          console.log ('wir überprüfen, ob gewünscht ist, dass die Verbände erneut eingebaut werden')
          _this.verbaendeselect = verbaendeArray[0] // Alle 
        }
        // else {
        //  _this.changeUsers() 
        //}
        
        
        // console.log (verbaendeArray)             
      },
    }
  }
</script>

<style>
  .gump-error-message {
    display:block;
  }
  tr:nth-child(even) {
    background-color: #f8f8f8;
  }
  .v-btn {
    margin-right: 16px;
    margin-bottom: 16px;
  }
  @media only screen and (max-width: 768px) {
    .mobile-full-width {
      display: flex;
      width: 100% !important;
      margin-left: 16px !important;
      border-top: 0;
      padding: 5px 16px !important;
    }
  }
</style>