import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import vuetify from './plugins/vuetify';
// import MultiFiltersPlugin from './plugins/MultiFilters' // allow multifilter on table lists
import axios from 'axios'
import JsonCSV from 'vue-json-csv'
// import VueAxios from 'vue-axios'
import '@babel/polyfill'


Vue.config.productionTip = false

Vue.use(VueRouter)
// Vue.use(MultiFiltersPlugin); // allow multifilter on table lists
// Vue.use(VueAxios, axios); 
Vue.use(axios); 

Vue.prototype.$http = axios;

Vue.component('downloadCsv', JsonCSV)

import User from '@/components/User'
import Home from '@/components/Home'
import PersonenEditieren from '@/components/PersonenEditieren'
import Untersuchungen from '@/components/Untersuchungen'
import Settings from '@/components/Settings'
import Logs from '@/components/Logs'
import Login from '@/components/Login'
import Account from '@/components/Account'
import Impressum from '@/components/Impressum'

import userauth from './mixin-userauth.js';
import api from './mixin-api.js';
// import themesetter from './mixin-theme.js';


const routes = [
    {
      path: '/user',
      name: 'User',
      component: User
    },
    {
      path: '/personen-editieren',
      name: 'PersonenEditieren',
      component: PersonenEditieren
    },
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/untersuchungen',
      name: 'Untersuchungen',
      component: Untersuchungen
    },  
    {
      path: '/settings',
      name: 'Settings',
      component: Settings
    },  
    {
      path: '/account',
      name: 'Account',
      component: Account
    },  
    {
      path: '/logs',
      name: 'Logs',
      component: Logs
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/impressum',
      name: 'Impressum',
      component: Impressum
    }
  ]

const router = new VueRouter({
  routes // short for `routes: routes`
})

new Vue({
  mixins: [userauth, api],
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
