<template>

  <v-container v-if="this.$root.adminonly && this.$root.loggedin">

    <h2>Settings</h2>
    <br />

    <v-card class="mb-5 pb-3">
      <v-card-text class="text-sm-left">
        <h3>Perioden definieren</h3>
        <br />
        Sie können hier angeben, ab und bis wann Personen einer bestimmten Periode untersucht werden können. Damit kann bspw. eine Untersuchung vom aktuellen Jahr noch im Folgejahr oder vom nächsten Jahr bereits jetzt durchgeführt werden. Wenn Sie einen Wert leer lassen, gilt das Kalenderjahr (1.1. für Anfang oder 31.12. für Ende der Periode). Beispiel: Sie können für das Jahr 2028 einstellen, dass Untersuchungen von 1.11.2027 bis 28.2.2029 möglich sind.<br /><br />

        <v-row
          >
          <v-col class="d-flex" cols="12" sm="4">
            <v-select
              v-model="selectedPeriod"
              :items="availablePeriods"
              
              hint="Periode muss ausgewählt sein"                    
              filled
              label="Periode auswählen"
              v-on:change="applyPeriodtimeframes"
              required
            ></v-select>
          </v-col>
        </v-row>

        <v-row justify="start">
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-menu
              v-model="fromdatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fromdate"
                  label="Anfangsdatum"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fromdate"
                @input="fromdatePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-menu
              v-model="untildatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="untildate"
                  label="Enddatum"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="untildate"
                @input="untildatePicker = false"

              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <br />
        <v-btn x-large color="primary" dark @click.stop="uploadCSVDialog = true" v-if="this.$root.adminonly" @click="savePeriods">Speichern</v-btn>

        <br />

        <v-dialog
          v-model="successDialog"
          max-width="290"
        >
          <v-card>
            <v-card-title class="headline">Erfolg</v-card-title>

            <v-card-text>
              {{ this.successDialogMsg }}
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="primary"
                text
                @click="successDialog = false"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>      

      </v-card-text>
    </v-card>

  </v-container>

  <v-container v-else>
    <h2>Kein Zugriff</h2>
    <router-link to="/login">Loggen Sie sich ein</router-link>
  </v-container>

</template>

<script>
  export default {
    data () {
      return {
        availablePeriods: [],
        selectedPeriod: [],
        periodtimeframes: [],

        standby: false,

        dialogperiodsaved: false,

        postFailed: false,
        userEditedSuccess: false,
        postFailedMsg: '',

        // fromdate: new Date().toISOString().substr(0, 10),
        // untildate: new Date().toISOString().substr(0, 10),
        fromdate: null,
        untildate: null,
        fromdatePicker: false,
        untildatePicker: false,

        successDialog: false,
        successDialogMsg: 'Die Daten wurden gespeichert!',
        search: '',

        valid: false,
       
        rules: {
          required: value => !!value && this.editedUserId == 0 || 'Erforderlich!',
        }
      }

    },
    mounted () {
      this.$root.checkIfLoggedIn()
      this.$root.thisPageAdminOnly()
      this.getAvailableYears()
      this.getPeriodtimeframes()     
    },
    methods: {
      uploadCSVDialogClose() {
        this.importSuccess = false
        this.csvfileinput = ''
        this.uploadCSVDialog = false
      },
      applyPeriodtimeframes() {
          // console.log (this.selectedPeriod);
          // console.log (this.periodtimeframes);

          let selectedPeriod = String(this.selectedPeriod);

          if (typeof (this.periodtimeframes[selectedPeriod]) !== 'undefined') {
            this.fromdate = this.periodtimeframes[selectedPeriod].fromdate
            this.untildate = this.periodtimeframes[selectedPeriod].untildate
          } else {
            this.fromdate = null
            this.untildate = null
          }

      },
      getAvailableYears() {
        return this.$http.get(this.$root.gefspotAPI + "/available-periods")
        .then(response => {
          // console.log (response)
          // console.log (response.data)
          this.availablePeriods = response.data
          this.selectedYear = response.data[1]
        });
      },
      getPeriodtimeframes () {

        let thisUser = this.$root.getUser()

        let _this = this
        _this.standby = true

        let data = {
          'userId': thisUser.userId,
          'authkey': thisUser.authkey
        }
        data = JSON.stringify(data);

        var xhr = new XMLHttpRequest();
        xhr.open("POST", this.$root.gefspotAPI + "/settings/get-periodtimeframes/", true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
        xhr.send("data=" + data);
        xhr.onload = function() {
          _this.standby = false
          var response = JSON.parse(this.response)
          console.log (response)
          console.log (response.result)
          console.log (JSON.parse(response.result))

          if (response.status == 'error') {
            _this.postFailed = true
            _this.postFailedMsg = response.msg
          }
          else if (response.status == 'success') {
            _this.periodtimeframes = JSON.parse(response.result);
          }

        };

      },
      savePeriods () {

        let thisUser = this.$root.getUser()

        let _this = this
        _this.standby = true

        let data = {
          'period': this.selectedPeriod,
          'fromdate': this.fromdate,
          'untildate': this.untildate,
          'userId': thisUser.userId,
          'authkey': thisUser.authkey
        }
        data = JSON.stringify(data);

        var xhr = new XMLHttpRequest();
        xhr.open("POST", this.$root.gefspotAPI + "/settings/save-periodtimeframe/", true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
        xhr.send("data=" + data);
        xhr.onload = function() {
          _this.standby = false
          var response = JSON.parse(this.responseText)
          console.log (response)
          console.log (response.status)

          if (response.status == 'error') {
            _this.postFailed = true
            _this.postFailedMsg = response.msg
          }
          else if (response.status == 'success') {
            _this.successDialog = true
            _this.successDialogMsg = 'Die Daten für die Periode ' + _this.selectedPeriod + ' wurden gespeichert!'
          }

        };

      }
    }
  }
</script>

<style>
  .gump-error-message {
    display:block;
  }
  tr:nth-child(even) {
    background-color: #f8f8f8;
  }
  @media only screen and (max-width: 768px) {
    .mobile-full-width {
      display: flex;
      width: 100% !important;
      margin-left: 16px !important;
      border-top: 0;
      padding: 5px 16px !important;
    }
  }
</style>