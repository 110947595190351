<template>

  <v-container v-if="this.$root.loggedin">

    <h2>Personen</h2>
    <br />

    <v-card class="mb-5 pb-3">
      <v-card-text class="text-sm-left">
        Bitte suchen Sie nach der Person, für die Sie eine Bestätigung der Untersuchung übermitteln wollen. Klicken Sie dann auf den roten Button "Untersuchung".
      </v-card-text>
    </v-card>


    <v-container row wrap class="justify-md-space-between">
      <v-flex x4 s12>
        <v-text-field
          v-model="search"
          prepend-icon="mdi-magnify"
          label="Suche"
          filled
          clearable
        ></v-text-field>
      </v-flex>
    </v-container>



    <v-data-table
      v-bind:headers="headers"
      v-bind:items="users"
      v-bind:search="search"
      :footer-props="{'items-per-page-options':[10, 25, 50, 100, -1]}"      
        sort-by="name"
      :loading="standby"
      class="elevation-1"
    >

      <template v-slot:item="row">
          <tr class="">
            <!--<td class="mobile-full-width">{{row.item.periode}}</td>-->
            <td class="mobile-full-width">{{row.item.vorname}}</td>
            <td class="mobile-full-width">{{row.item.nachname}}</td>
            <td class="mobile-full-width">{{row.item.geburtsdatum}}</td>
            <td class="mobile-full-width">{{row.item.verband}}</td>
            <td class="mobile-full-width">{{row.item.periode}}</td>
            <td class="mobile-full-width">
              <v-btn
                color="primary"
                depressed
                @click.stop="openUntersuchungDialog(row.item.id)"
              >
                <v-icon left>mdi-eye-check</v-icon>
                Untersuchung
              </v-btn>
            </td>      
          </tr>
      </template>      

    </v-data-table>


   <v-container>
      <br />
      <v-row align="center">
        <download-csv
          :data   = "users">
          <v-btn 
            dark
            color="grey darken-1"
            >
            <v-icon left>mdi-file-download</v-icon> CSV Export - alle Personen ohne Untersuchung
          </v-btn>
        </download-csv>
      </v-row>
    </v-container>


    <v-dialog v-model="untersuchungDialog" persistent max-width="600px">
      <v-card>

        <v-card-title>
          <span class="headline">Untersuchung bestätigen</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>

              <v-col cols="12">
                <v-form ref="uploadCSVFrom">

                  <v-alert
                    dense
                    outlined
                    type="info"
                  >
                        Sie bestätigen mit dem Klick auf "Bestätigen", dass die Untersuchung an folgender Person durchgeführt wurde:<br /><br />
                        <h3>{{untersuchtePerson.vorname}} {{untersuchtePerson.nachname}}</h3>
                        {{untersuchtePerson.verband}}

                    <br />
                    <br />
                  </v-alert>

                  <br />
                  <v-col cols="12">
                    <v-text-field
                      label="Untersuchender Arzt (optional)"
                      v-model="arzt"                      
                      name="arzt"                      
                      filled
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-textarea
                      filled
                      v-model="anmerkungen"
                      name="anmerkungen"
                      label="Anmerkungen zum Untersuchungstermin (optional)"
                    ></v-textarea>
                  </v-col>

                </v-form>
              </v-col>
      
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey lighten-1" @click="untersuchungDialogAbbrechen">Abbrechen</v-btn>
          <v-btn color="primary" @click="untersuchungEinreichen">Bestätigen</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <v-dialog
      v-model="standby"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Einen Moment bitte...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="successDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">Erfolg</v-card-title>

        <v-card-text>
          {{ this.successDialogMsg }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            text
            @click="successDialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>

  <v-container v-else>
    <h2>Kein Zugriff</h2>
    <router-link to="/login">Loggen Sie sich ein</router-link>
  </v-container>

</template>

<script>
  export default {
    data () {
      return {
        uploadCSVDialog: false,
        csvfileinput: '',
        availablePeriods: [],
        csvfileperiode: '',

        editUserDialog: false,
        editUserDialogHeadline: 'Neuen User anlegen',

        notvalidated: false,
        standby: false,

        untersuchungDialog: false,

        name: '',
        email: '',
        rolle: 'Untersuchungsstelle',
        passwort: '',
        passwortRepeat: '',
        zusatzinfo: '',
        editedUserId: 0,

        postFailed: false,
        userEditedSuccess: false,
        postFailedMsg: '',

        successDialog: false,
        successDialogMsg: 'Der Vorgang wurde erfolgreich beendet!',
        search: '',
        
        users: [],
        userroles: [
          'Untersuchungsstelle', 'Admin'
        ],
        untersuchungsstelle: '',
        untersuchtePerson: [],
        arzt: '',
        anmerkungen: '',

        pagination: {},
        headers: [
          // { text: 'Periode', value: 'periode', sortable: true },
          { text: 'Vorname', value: 'vorname', sortable: true },
          { text: 'Nachname', value: 'nachname', sortable: true },
          { text: 'Geburtsdatum', value: 'geburtsdatum', sortable: false },
          { text: 'Verband', value: 'verband', sortable: false },
          { text: 'Periode', value: 'periode', sortable: true },
          { text: '', value: null, sortable: false },
          // { text: 'Registered', value: 'registered', sortable: false },
        ],
        valid: false
      }

    },
    mounted () {
      this.$root.checkIfLoggedIn()

      this.getAvailableYears()
        .then(() => {
          this.users = this.getPersonen()
        })
        .catch(e => {
          this.errors.push(e)
        });
      
    },
    methods: {
      getAvailableYears() {
        return this.$http.get(this.$root.gefspotAPI + "/available-periods")
        .then(response => {
          // console.log (response)
          // console.log (response.data)
          this.availablePeriods = response.data
          this.selectedYear = response.data[1]
        });
      },
      openUntersuchungDialog (id) {
        this.untersuchungDialog = true
        
        let userToUse

        for (var i=0;i<this.users.length;i++) {
          let thisUser = this.users[i]
          if (thisUser.id == id) {
            userToUse = thisUser
            break
          }
        }
        console.log (userToUse)
        this.untersuchtePerson = userToUse

      },
      untersuchungEinreichen() {
        let thisUser = this.$root.getUser()

        let _this = this
        _this.standby = true

        let data = {
          'userId': thisUser.userId,
          'authkey': thisUser.authkey,
          'untersuchterId' : _this.untersuchtePerson.id,
          'arzt': _this.arzt,
          'notizen': _this.anmerkungen,
        }
        data = JSON.stringify(data);

        var xhr = new XMLHttpRequest();
        xhr.open("POST", this.$root.gefspotAPI + '/personen/untersuchung-einreichen', true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
        xhr.send("data=" + data);

        xhr.onload = function() {
          _this.standby = false
          console.log(this.responseText);
          var response = JSON.parse(this.responseText)
          console.log (response)

          if (response.status == 'error') {
            _this.postFailed = true
            _this.postFailedMsg = response.msg
          }
          else if (response.status == 'success') {
            _this.untersuchungDialogAbbrechen()
            _this.successDialogMsg = 'Vielen Dank! Die Untersuchung wurde erfolgreich bestätigt!'
            _this.successDialog = true            
            // _this.getUsers()// Legacy
            _this.getPersonen()
          }
        }

      },
      untersuchungDialogAbbrechen() {
        this.anmerkungen = ''
        this.arzt = ''
        this.untersuchungDialog = false
      },
      /*
      // LEGACY 
      getUsers () {

        let thisUser = this.$root.getUser()

        let _this = this
        _this.standby = true
        console.log (_this.availablePeriods);
        if (_this.selectedYear == 0)  {
          _this.selectedYear = _this.availablePeriods[1] 
        }       

        let data = {
          'userId': thisUser.userId,
          'authkey': thisUser.authkey
        }
        data = JSON.stringify(data);

        var xhr = new XMLHttpRequest();
        xhr.open("POST", this.$root.gefspotAPI + "/personen/all/" + _this.selectedYear, true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
        xhr.send("data=" + data);
        xhr.onload = function() {
          _this.standby = false
          var response = JSON.parse(this.responseText)
          console.log (response)

          if (response.status == 'error') {
            _this.postFailed = true
            _this.postFailedMsg = response.msg
          }
          else if (response.status == 'success') {
            _this.users = response.result;
          }

        };

      }, */
      getPersonen () {

        let thisUser = this.$root.getUser()

        let _this = this
        _this.standby = true
        console.log (_this.availablePeriods);
        if (_this.selectedYear == 0)  {
          _this.selectedYear = _this.availablePeriods[1] 
        }       

        let data = {
          'userId': thisUser.userId,
          'authkey': thisUser.authkey
        }
        data = JSON.stringify(data);

        var xhr = new XMLHttpRequest();
        xhr.open("POST", this.$root.gefspotAPI + "/personen/get-alle/", true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
        xhr.send("data=" + data);
        xhr.onload = function() {
          _this.standby = false
          var response = JSON.parse(this.responseText)
          console.log (response)

          if (response.status == 'error') {
            _this.postFailed = true
            _this.postFailedMsg = response.msg
          }
          else if (response.status == 'success') {
            _this.users = response.result;
          }

        };

      },
    }
  }
</script>

<style>
  .gump-error-message {
    display:block;
  }
  tr:nth-child(even) {
    background-color: #f8f8f8;
  }
  @media only screen and (max-width: 768px) {
    .mobile-full-width {
      display: flex;
      width: 100% !important;
      margin-left: 16px !important;
      border-top: 0;
      padding: 5px 16px !important;
    }
  }
</style>