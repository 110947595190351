export default {
  data: function () {
    return {
      loggedInUser: [],
      loggedin: false,
      adminonly: false
    }
  },	
  mounted: function () {
    this.hellouserauth();
   	this.loggedIn()
   	this.checkIfLoggedIn()
   	this.isAdmin()
  },
  created: function () {
    this.hellouserauth()
   	this.loggedIn()
   	this.checkIfLoggedIn()
   	this.isAdmin()  	
  },
  methods: {
    hellouserauth: function () {
      // console.log('hello from userauth-mixin!')
      // console.log('api is: ' +  this.gefspotAPI)
    },
    checkIfLoggedIn: function () {
      if (this.loggedIn === false || localStorage.getItem("user") === null) {
        this.$router.push('/login')
      }
    },
    loggedIn: function () {
      if (localStorage.getItem("user") === null) {
      	this.loggedin = false
        return false;
      } else {
      	this.loggedin = true
        return true;
      }
    },
    isAdmin: function () {
      var thisUser = this.getUser()
      // console.log (thisUser)
      if ( thisUser != false && thisUser.admin == true) {
        this.adminonly = true	
        return thisUser.admin;
      } else {
      	// this.$router.push('/login')
      }
    },
    getUser: function () {
      if (localStorage.getItem("user") !== null) {
      	return JSON.parse(localStorage.getItem("user"))
      } else {
      	return false
      }
    },
    thisPageAdminOnly: function () {
    	if (this.isAdmin() != true) {
    		this.$router.push('/')
    	}
    },
    checkStatus: function () {

      if (localStorage.getItem("user") === null) {
        console.log ('user nicht eingeloggt - kein localStorage item vorhanden.');
        this.$router.push('/login')
        return;
      }

      var user = JSON.parse(localStorage.getItem('user'));
      // console.log (user);

      let _this = this
      _this.standby = true

      let data = {
        'userId': user.userId,
        'authkey': user.authkey
      }
      data = JSON.stringify(data);

      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.gefspotAPI + "/users/login-status/", true);
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
      xhr.send("data=" + data);
      xhr.onload = function() {
        _this.standby = false
        // console.log(this.responseText);
        var response = JSON.parse(this.responseText)
		// console.log (response)
        _this.loggedInUser = response
        // console.log (_this.loggedInUser)

		_this.loggedIn()
		_this.isAdmin()
      };

    },
    logout: function () {

      if (localStorage.getItem("user") === null) {
        this.$router.push('/login')
        return;
      }

      var user = JSON.parse(localStorage.getItem('user'));

      let _this = this

      let data = {
        'userId': user.userId,
        'authkey': user.authkey
      }
      data = JSON.stringify(data);

      _this.loggedInUser = []
      _this.loggedin = false
      _this.adminonly = false
      localStorage.removeItem('user');

      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.gefspotAPI + "/users/logout/", true);
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
      xhr.send("data=" + data);
      xhr.onload = function() {
        _this.$router.push('/login')
      };

    }

  }
}