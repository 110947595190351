<template>
  <v-container v-if="this.$root.loggedin">
    <h2>Account</h2>
    <br />

    <v-card class="mb-5 pb-3">
      <v-card-text class="text-sm-left">
        
        <div v-if="this.$root.loggedInUser.admin"><h3>Admin-Account</h3><br /></div>
        <div v-if="this.$root.loggedInUser.name"><b>{{this.$root.loggedInUser.name}}</b><br /></div>
        <div v-if="this.$root.loggedInUser.zusatzinfo"><b>{{this.$root.loggedInUser.zusatzinfo}}</b><br /><br /></div>
        
        UserID: {{this.$root.loggedInUser.userId}}<br />
        Login: {{this.$root.loggedInUser.userEmail}}<br />
        
        <br />
        <!--
        <v-btn color="secondary" v-on:click="this.$root.checkStatus">checkStatus</v-btn>
        <v-btn color="secondary" v-on:click="this.$root.isAdmin">isAdmin</v-btn>
        -->
        <v-btn color="primary" v-on:click="this.$root.logout">Logout</v-btn>

      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    mounted () {
      this.$root.checkStatus()
    }
  }
</script>